import React, { useEffect, useState } from "react";
import Footer from "../directives/footer";
import axios from "axios";
import { getPolicyAction } from "../Action/user.action";


const PrivacyPolicy = () => {

    const [privacyData,setPrivacyData] = useState("")

    useEffect(() => {
        getData()
    },[])

    const getData = async()=>{

        try {
        
        let res = await getPolicyAction()
        
        if(res?.data){

        setPrivacyData(res?.data[0]?.privacyPolicy)
        }
    
    } catch (error) {
           console.log(error);
            
    }

    }
  return (
    <>
      <div className="page">
        <div className="content">
          <div className="main-content">
            <div className="block justify-between page-header md:flex">
              {/* <div>
                <h3 className="text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium">
                  {" "}
                  Privacy&Policy
                </h3>
              </div> */}
            </div>
                <div className="col-lg-10">
                    <div className="policy-main" style={{backgroundColor:"#FFFFFF",padding:"20px",boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                        <h5> Privacy&Policy</h5>
                        <pre className="privacy">
                         {privacyData}
                         </pre>
                    </div>
                </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;

import React, { useEffect, useState } from "react";
import Footer from "../directives/footer";
import {getaccessibilityAction} from "../Action/user.action"

const TermsandCondition = () => {
  
const [termsData,setTermsData] = useState("");

useEffect(()=>{
    gettermsData();
},[]);

const gettermsData =async()=>{

    try {
        
        let res = await getaccessibilityAction()
        
        
        if(res?.data){
           
            
            setTermsData(res?.data[0]?.termsAndCondition)
        }
    
    } catch (error) {
           console.log(error);
            
    }

}
 
  return (
    <>
      <div className="page">
        <div className="content">
          <div className="main-content">
            <div className="block justify-between page-header md:flex">
              {/* <div>
                <h3 className="text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium">
                  {" "}
                  Privacy&Policy
                </h3>
              </div> */}
            </div>
                <div className="col-lg-10">
                    <div className="policy-main" style={{backgroundColor:"#FFFFFF",padding:"20px",boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                        <h5> Terms&Condition</h5>
                        <pre className="privacy">{termsData}</pre>
                    </div>
                </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TermsandCondition;
